import styles from "./S5_TrackOrder.module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Skeleton,
  Typography,
} from "@mui/material";
import { ButtonCust, StepperCustNew } from "../widgets";
import Config from "../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import {
  StepperStatus,
  OrderStatus,
} from "../../features/trackorder/trackOrder";
import { CustomerStatus } from "../../enums";
import EnvConfig from "../../config/env.config.json";
import { useAppSelector } from "../../app/hooks";
import { RoutingURLs } from "../../config/RoutingURLs";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import trackorder from "../../assets/Common/trackorder.svg";
import { InternalBanner } from "../common";
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const T5_TrackOrder = (props: any) => {
  const navigate = useNavigate();
  const {
    content,
    trackOrderData,
    planData,
    customerData,
    shipmentTrackingOpsMessages,
  } = props;
  const [trackingLoader, setTrackingLoader] = useState<boolean>(true);
  const GAAndFBEventsHandler = () => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.trackOrder,
      category: gaCategory.activation,
      buttonValue: content.act_now,
    });
  };
  useEffect(() => {
    setTimeout(() => {
      setTrackingLoader(false);
    }, 2000);
  }, []);
  const { trackOrderStatus, currentOderNo } = useAppSelector(
    (state: any) => state?.trackorder || {}
  );
  const onClickHandler = (navigationPath: string, redirectionPage: string) => {
    if (EnvConfig.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED) {
      // navigationPath = RoutingURLs.deviceConfirmCheck;
      navigationPath = navigationPath;
    }
    if (redirectionPage === "_self") {
      navigate(navigationPath);
    } else {
      window.open(navigationPath, redirectionPage);
    }
    GAAndFBEventsHandler();
  };
  const onArrowBack = () => {
    if (customerData?.additionalLines >= 1) {
      navigate(RoutingURLs.account, {
        state: {
          navigateFromNotification: true,
        },
      });
    } else {
      navigate(RoutingURLs.myAccount);
    }
  };

  const getSteps = () => {
    if (trackOrderData.cId && customerData.id) {
      let time = new Date(trackOrderData.orderDate);
      let time1 = time
        .toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
        ?.replace(/ /g, "")
        ?.toLowerCase();

      let labeledTime: any = trackOrderData.labelledOn
        ? new Date(trackOrderData.labelledOn)
        : "";
      let labeled1: any = labeledTime
        ? labeledTime
            .toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            ?.replace(/ /g, "")
            ?.toLowerCase()
        : "";

      let dateDelivery: any = trackOrderData.deliveredOn
        ? new Date(trackOrderData.deliveredOn)
        : trackOrderData.estimatedDeliveryDate
        ? new Date(trackOrderData.estimatedDeliveryDate)
        : "";
      let dateDelivery1: any = dateDelivery
        ? dateDelivery
            .toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            ?.replace(/ /g, "")
            ?.toLowerCase()
        : "";

      let shippedTime: any = trackOrderData.shippedOn
        ? new Date(trackOrderData.shippedOn)
        : "";
      let shippedTime1: any = shippedTime
        ? shippedTime
            .toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            ?.replace(/ /g, "")
            ?.toLowerCase()
        : "";
      let cancelledTime: any = trackOrderData.orderCanceledOn
        ? new Date(trackOrderData.orderCanceledOn)
        : "";
      let cancelledTime1: any = cancelledTime
        ? cancelledTime
            .toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            ?.replace(/ /g, "")
            ?.toLowerCase()
        : "";
      let stepperObj = [
        {
          label: StepperStatus.OrderReceived,
          text: `${
            months[time.getMonth()]
          } ${time.getDate()}, ${time.getFullYear()}, at ${time1}`,
          icon: content?.received_status_icon?.data?.attributes?.url,
          labeltext: StepperStatus.OrderReceived,
          date: `${time.getDate()} ${
            months[time.getMonth()]
          }, ${time.getFullYear()} `,
          simcards: trackOrderStatus?.data?.items
            ?.map((obj: any) => obj?.quantity)
            .reduce((acc: any, cur: any) => acc + cur, 0),
          simCardLabel: `${content.sim_cards_text}`,
          keyProp: 0,
        },
        {
          label: StepperStatus.InProgress,
          text:
            labeledTime &&
            labeled1 &&
            `${labeledTime.getDate()} ${
              months[labeledTime.getMonth()]
            }, ${labeledTime.getFullYear()}`,
          labeltext: content?.in_pro_text,
          icon: content?.Inprogress_status_icon?.data?.attributes?.url,
          simCardLabel: null,
          simcards: null,
          keyProp: 1,
        },
        {
          label: StepperStatus.OrderShipped,
          text:
            shippedTime &&
            shippedTime1 &&
            `${shippedTime.getDate()} ${
              months[shippedTime.getMonth()]
            }, ${shippedTime.getFullYear()}${"\n"}${shippedTime1}`,
          labeltext: content?.order_sh_text,
          icon: content?.shipped_status_icon?.data?.attributes?.url,
          simCardLabel: null,
          simcards: null,
          keyProp: 2,
        },
        {
          label: StepperStatus.DELIVERED,
          text:
            trackOrderData.orderStatus === OrderStatus.Delivered
              ? `${
                  months[dateDelivery.getMonth()]
                } ${dateDelivery.getDate()}, ${dateDelivery.getFullYear()}${"\n"}${dateDelivery1}`
              : "",
          labeltext:
            labeledTime && labeled1
              ? StepperStatus.DELIVERED
              : `${content?.estimated_note_txt}${
                  months[dateDelivery.getMonth()]
                } ${dateDelivery.getDate()}, ${dateDelivery.getFullYear()}`,
          icon: content?.delivered_status_icon?.data?.[0].attributes?.url,
          estimatedDelivery: `${
            months[dateDelivery.getMonth()]
          } ${dateDelivery.getDate()}, ${dateDelivery.getFullYear()}`,
          simCardLabel: null,
          simcards: null,
          keyProp: 3,
        },
      ];
      let finalStepperObj: any[] = [];
      if (trackOrderData.orderStatus === OrderStatus.Cancelled) {
        const cancelledObj = [
          {
            label: StepperStatus.Cancelled,
            text:
              cancelledTime && cancelledTime1
                ? `${content.cancel_txt}${"\n"} ${cancelledTime.getDate()} ${
                    months[cancelledTime.getMonth()]
                  }, ${cancelledTime.getFullYear()}${"\n"}
                  ${cancelledTime1}`
                : `${content.cancel_txt}`,
            simCardLabel: null,
            simcards: null,
            keyProp: 4,
          },
        ];
        if (trackOrderData.lastOrderStatus === OrderStatus.Ordered) {
          finalStepperObj.push(stepperObj[0], ...cancelledObj);
        }
      } else {
        finalStepperObj = [...stepperObj];
      }
      return finalStepperObj;
    } else {
      return [{ label: StepperStatus.OrderReceived }, {}, {}, {}];
    }
  };

  const renderLiveOpsMessages = () => {
    if (shipmentTrackingOpsMessages?.length <= 0) return null;
    return (
      <Typography component="div" sx={{ m: "20px 40px", textAlign: "left" }}>
        <Typography
          component="div"
          sx={{
            color: "var(--primary_brand_color)",
            fontSize: "16px",
            fontFamily: "var(--font_family_Medium)",
          }}
        >
          Note:
        </Typography>
        {shipmentTrackingOpsMessages.map((opsMessage: any) => {
          return (
            <Typography
              component="div"
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "14px",
                wordBreak: "break-all",
              }}
              key={opsMessage.id}
            >
              <Typography
                component="span"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "inherit",
                }}
              >
                {opsMessage.messageType}:{" "}
              </Typography>
              {opsMessage.message}
            </Typography>
          );
        })}
      </Typography>
    );
  };

  let activeStep: number = 0;
  if (trackOrderData && trackOrderData.cId) {
    activeStep =
      trackOrderData.orderStatus === OrderStatus.Ordered
        ? 0
        : trackOrderData.orderStatus === OrderStatus.Labelled ||
          trackOrderData.orderStatus === OrderStatus.Cancelled
        ? 1
        : trackOrderData.orderStatus === OrderStatus.Shipped
        ? 2
        : trackOrderData.orderStatus === OrderStatus.Delivered
        ? 3
        : 0;
  }
  let stepsData: any = getSteps();
  let customerDetails: any = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );

  return (
    <>
      {/* <InternalBanner
        // key={key}
        pageName={"MyAccount"}
        apiEndPoint={"myAccBanner"}
        type={"internal"}
      /> */}
      <Box
        sx={{
          background: "var(--lightgrey)",
          paddingTop: { xs: "20px", sm: "72px" },
        }}
      >
        <Box
          onClick={() => {
            customerDetails.status === CustomerStatus.ACTIVE
              ? navigate(RoutingURLs.postActivationHome)
              : navigate(RoutingURLs.myAccount);
          }}
          sx={{
            display: { xs: "none", sm: "flex" },
            width: "18px",
            height: "18px",
            position: "absolute",
            left: "5rem",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon sx={{ width: "18px" }} />
          <Box>Back</Box>
        </Box>
        <KeyboardBackspaceIcon
          sx={{
            display: { xs: "block", sm: "none" },
            position: "absolute",
            left: "1.4rem",
            top: "5.7rem",
            color: "var(--black)",
            cursor: "pointer",
          }}
          onClick={() => {
            customerDetails.status === CustomerStatus.ACTIVE
              ? navigate(RoutingURLs.postActivationHome)
              : navigate(RoutingURLs.myAccount);
          }}
        />
        <Typography
          component={"h5"}
          variant={"h5"}
          fontFamily={"var(--font_family_Bold)"}
          pb={{ xs: "15px", sm: "20px" }}
          textAlign="center"
          width="100%"
          sx={{
            fontSize: { xs: "20px", md: "24px" },
            color: "var(--title-color_1)",
          }}
        >
          {content.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              // border: "1px solid rgba(0,0,0,.2)",
              width: { xs: "92%", sm: "60%" },
              // height: "600px",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: { xs: "12px" },
              boxShadow: { xs: "unset !important" },
              marginBottom: "45px",
            }}
          >
            <Box
              component="p"
              sx={{
                fontFamily: "var(--font_family_Bold)",
                mt: "30px",
                mb: "7px",
                fontSize: { xs: "18px", sm: "20px" },
              }}
            >
              {planData &&
              planData.displayName &&
              customerData &&
              customerData.id ? (
                <>
                  {planData.displayName}&nbsp;-&nbsp;
                  <Box
                    component="span"
                    sx={{
                      color: "var(--primary_color)",
                      fontFamily: "var(--font_family_Bold)",
                      fontSize: { xs: "18px", sm: "20px" },
                    }}
                  >
                    $
                    {planData &&
                      planData.baseLinePrice &&
                      planData.baseLinePrice.toFixed(2)}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      fontSize: "12px",
                      fontFamily: "var(--font_family_Medium)",
                      color: "var(--text_color)",
                    }}
                  >
                    {content?.plan_perMonth}
                  </Box>
                </>
              ) : null}
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "var(--font_family_Medium)",
                  // mt: { xs: "18px" },
                  mb: { xs: "10px" },
                }}
              >
                {content?.psim_ordertxt} &nbsp;
                {!currentOderNo ? (
                  <Box component="span" className={styles.order_circle}>
                    <CircularProgress />
                  </Box>
                ) : (
                  currentOderNo
                )}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "var(--font_family_Semibold)",
                // mt: { xs: "18px" },
                mb: { xs: "10px" },
                textAlign: "center",
              }}
            >
              {stepsData.map((item: any, index: number) => {
                return item.estimatedDelivery && activeStep !== 3 ? (
                  <>
                    {`${content?.estimated_delivery_txt} ${item.estimatedDelivery}`}
                  </>
                ) : null;
              })}
            </Typography>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <hr style={{ border: "1px solid #F8F8F8" }}></hr>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              sx={{ width: { xs: "300px", sm: "100%" } }}
            >
              <StepperCustNew
                activeStep={activeStep}
                steps={getSteps()}
                stepOneExtraContentTR={true}
                orderStatus={trackOrderData.orderStatus}
                {...props}
                sx={{ padding: "24px", textAlign: "center" }}
              />
            </Box>
            <Box
              sx={{
                fontSize: "16px",
                color: "var(--lite_black_3)",
                fontFamily: "var(--font_family_Semibold)",
                my: "20px",
              }}
            >
              {content?.shipment_progress_txt}{" "}
              {`${
                trackOrderData?.physicalSimQuantity
                  ? `(${trackOrderData?.physicalSimQuantity} SIM ${
                      trackOrderData?.physicalSimQuantity > 1 ? "cards" : "card"
                    })`
                  : ""
              } `}
            </Box>
            <Box>
              {trackingLoader ? (
                <>
                  <Skeleton
                    animation="wave"
                    width="100%"
                    height={25}
                    sx={{ borderRadius: "2px", my: "5px" }}
                  />
                  <Skeleton
                    animation="wave"
                    width="100%"
                    height={25}
                    sx={{ borderRadius: "2px" }}
                  />
                  <Skeleton
                    animation="wave"
                    width="100%"
                    height={25}
                    sx={{ borderRadius: "2px", my: "5px" }}
                  />
                  <Skeleton
                    animation="wave"
                    width="100%"
                    height={25}
                    sx={{ borderRadius: "2px" }}
                  />
                </>
              ) : (
                <Box display="flex" mb="20px">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="baseline"
                  >
                    {stepsData &&
                      stepsData.map((item: any, index: number) => {
                        return (
                          <Box display="flex" padding="15px" key={index}>
                            <Box
                              component="img"
                              alt={"circular_loader"}
                              src={item?.icon}
                              sx={{
                                width: { xs: "25px" },
                                mr: "20px",
                                height: "25px",
                              }}
                            />
                            <Box display="flex" flexDirection="column">
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "var(--font_family_Regular)",
                                }}
                              >
                                {item?.labeltext}
                              </Typography>
                              <Box
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "var(--font_family_Bold)",
                                }}
                              >
                                {item.text &&
                                  item.text.split("\n").map((i: any) => {
                                    return <span>{i}</span>;
                                  })}
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              )}
              <Box textAlign="center" sx={{ my: { xs: "12px" } }}>
                {trackOrderData?.orderStatus !== OrderStatus.Cancelled && (
                  <>
                    {content?.res?.home_btn && activeStep !== 3 ? (
                      <Box>
                        <ButtonCust
                          variantType={Config.PRIMARY_BUTTON_TYPE}
                          sx={{ width: { xs: "200px" } }}
                          onClick={() => {
                            if (
                              customerData?.isPrimary &&
                              customerData?.status === CustomerStatus.ACTIVE
                            ) {
                              navigate(RoutingURLs.postActivationHome);
                            } else {
                              navigate(content?.res?.home_btn_url);
                            }
                          }}
                        >
                          {content?.res?.home_btn}
                        </ButtonCust>
                      </Box>
                    ) : EnvConfig.TRACK_ORDER_ACTIVATE_BTN ? (
                      <Box>
                        <ButtonCust
                          disabled={activeStep === 3 ? false : true}
                          variantType={Config.PRIMARY_BUTTON_TYPE}
                          onClick={() =>
                            onClickHandler(RoutingURLs.activation, "_self")
                          }
                        >
                          {content.btn_text}
                        </ButtonCust>
                      </Box>
                    ) : (
                      <>
                        {activeStep === 3 && (
                          <Box mb="20px">
                            <ButtonCust
                              variantType={Config.PRIMARY_BUTTON_TYPE}
                              onClick={() =>
                                onClickHandler(RoutingURLs.activation, "_self")
                              }
                            >
                              {content.btn_text}
                            </ButtonCust>
                          </Box>
                        )}
                      </>
                    )}
                    {trackOrderData?.orderStatus !== OrderStatus.Delivered && (
                      <>
                        <Box
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontSize: "16px",
                            letterSpacing: " normal",
                            textAlign: "center",
                            color: "var(--lite_black_3)",
                          }}
                          mt="32px"
                        >
                          {content.rec_kit}
                        </Box>
                        <Box
                          textAlign={{ xs: "center" }}
                          sx={{
                            fontSize: "14px",
                            fontFamily: "var(--font_family_Semibold)",
                            fontWeight: "var(--font_weight_3)",
                            cursor: "pointer",
                            color: "var(--primary_color)",
                            pt: "5px",
                          }}
                          onClick={() =>
                            onClickHandler(RoutingURLs.activation, "_self")
                          }
                        >
                          {content.act_now}
                        </Box>
                      </>
                    )}
                    {Config.FREE_SHIPPING_ACTIVATED &&
                    customerData?.status === CustomerStatus.INACTIVE &&
                    content?.track_img?.data?.attributes?.url ? (
                      <>
                        <Box
                          sx={{
                            width: "40px",
                            height: "35px",
                          }}
                          mt="30px"
                          mb="10px"
                          component="img"
                          src={content?.track_img?.data?.attributes?.url || ""}
                          alt={
                            content?.track_img?.data?.attributes
                              ?.alternativeText
                          }
                        />
                        <Box
                          sx={{
                            fontFamily: "var(--font_family_Semibold)",
                            fontSize: "14px",
                            fontWeight: "var(--font_weight_0)",
                            letterSpacing: " normal",
                            lineHeight: "21px",
                            textAlign: "center",
                            color: "var(--text_color_5)",
                            margin: {
                              xs: "0 18px 0",
                              sm: "0 10% 0",
                              md: "0 16% 0",
                            },
                          }}
                        >
                          {BrandNameUpdate(content.act_your_line)}
                        </Box>
                      </>
                    ) : null}
                  </>
                )}
              </Box>
              <Box>
                {trackOrderData?.orderStatus === OrderStatus.Delivered &&
                (content?.res?.order_deliver_note ||
                  content?.res?.order_deliver_desc) ? (
                  <Box
                    sx={{
                      paddingX: { xs: "10px", md: "85px" },
                      lineHeight: "24px",
                    }}
                  >
                    {content?.res?.order_deliver_note ? (
                      <Box
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontSize: "14px",
                          fontWeight: "var(--font_weight_0)",
                          letterSpacing: " normal",
                          textAlign: "left",
                          color: "var(--primary_color)",
                        }}
                        mt="20px"
                      >
                        {content?.res?.order_deliver_note}
                      </Box>
                    ) : null}
                    {content?.res?.order_deliver_desc ? (
                      <Box
                        textAlign={{ xs: "left" }}
                        sx={{
                          fontSize: "14px",
                          fontFamily: "var(--font_family_Medium)",
                          fontWeight: "var(--font_weight_0)",
                          cursor: "pointer",
                          pt: "5px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: BrandNameUpdate(
                            content?.res?.order_deliver_desc
                          ),
                        }}
                      />
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Box
              sx={{
                px: "90px",
                mb: { xs: "6px", sm: "20px" },
                width: "85%",
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                flexDirection: { xs: "column", sm: "auto" },
              }}
            >
              <Box
                component="span"
                sx={{
                  fontFamily: "var(--font_family_Bold)",
                  fontSize: "16px",
                  color: "var(--text_color)",
                }}
              >
                {content?.res?.order_deliver_note}&nbsp;
              </Box>
              <Box
                component="p"
                sx={{
                  textAlign: { xs: "left", sm: "center" },
                  mt: { xs: "6px", sm: "10px" },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontFamily: "var(--font_family_Regular)",
                    fontSize: { xs: "14px", sm: "16px" },
                    lineHeight: "25px",
                    color: "var(--text_color)",
                  }}
                >
                  {content?.res?.note_desc}&nbsp;
                </Box>
                <Box
                  component="a"
                  sx={{
                    color: "var(--primary_color)",
                    cursor: "pointer",
                  }}
                  href={content?.psim_clickhere_link}
                  target="_blank"
                >
                  {content?.psim_clickhere_txt}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
    //           {EnvConfig.IS_TRACK_ORDER_PAGE_BACK_FUNCTIONALITY_REQD && (
    //             <ArrowBackIosIcon
    //               sx={{ cursor: "pointer", width: "24px" }}
    //               onClick={onArrowBack}
    //             />
    //           )}
  );
};

export default T5_TrackOrder;
