import {
  Box,
  Card,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { ButtonCust } from "../../../widgets";
import { calculateCost, calculateUnlimitedCost, PlanProps } from "./PlanCard";
import styles from "./S13_PlanCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useEffect, useState } from "react";
import { NutritionLabel } from "../NutritionLabel/NutritionLabel";
import { getConfigKeys } from "../../../../features/activation/activationSlice";
import { useAppDispatch } from "../../../../app/hooks";
import { getAppData } from "../../../../features/checkout/checkoutSlice";

const T13_PlanCard = (props: PlanProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showAnswer, setShowAnswer] = useState<any>(true);
  const handleAnswer = () => {
    if (showAnswer) return setShowAnswer(false);
    return setShowAnswer(true);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);
  const {
    plandata,
    planContent,
    tagLabel,
    tagBgColor,
    tagTxtColor,
    selectedLine,
    planSelectionHandler,
  } = props;

  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    current_plan,
    fade_out,
    home_page_plan_card,
    tag,
    wrapper_div,
    mb_img,
  } = styles;
  const {
    per_month_text,
    mbl_img,
    video_img,
    tax_text,
    btn_txt,
    data,
    hd_vid,
    sd_vid,
    mb_data,
    hotspot_img,
    no_hpt_fee,
    res,
    res1,
    res2,
  } = planContent || {};

  return (
    <Stack className={wrapper_div}>
      <Grid container className={clsx(cards_div, plans_div)}>
        {plandata.length > 0 &&
          plandata.map((el: any, index: number) => {
            const { planCost, planPerLinesCost } = el?.unlimited
              ? calculateUnlimitedCost(el, selectedLine)
              : calculateCost(el, selectedLine);
            const isCurrentPlan =
              details?.reachPlanDisplayName === el?.displayName;
            return (
              <Card
                id={`home${index}`}
                key={index}
                className={clsx(home_page_plan_card, plan_page_plan_card)}
                raised
                elevation={isMobile ? 0 : 8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                }}
              >
                {isCurrentPlan && (
                  <>
                    {!tagLabel && (
                      <Box
                        className={tag}
                        sx={{
                          backgroundColor: tagBgColor
                            ? tagBgColor
                            : "var(--primary_background)",
                          color: tagTxtColor ? tagTxtColor : "var(--white)",
                        }}
                      >
                        {planContent?.current_plan}
                      </Box>
                    )}
                  </>
                )}
                <Box sx={{ minHeight: "335px" }}>
                  <Typography
                    component={"h5"}
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      fontSize: { xs: "20px", md: "18px" },
                      color: "var(--text_color_12)",
                      fontWeight: "600",
                      my: "6px",
                      textAlign: "center",
                    }}
                  >
                    {el.displayName}
                  </Typography>
                  <Typography
                    component={"div"}
                    sx={{
                      fontFamily: "var(--font_family_Bold)",
                      fontSize: { xs: "18px", md: "20px" },
                      color: "var(--text_color)",
                      my: "6px",
                      textAlign: "center",
                    }}
                  >
                    {el.planData + el.dataUnit}
                    {el.planType === "LSD" ? "+" : ""}
                  </Typography>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "32px",
                        fontFamily: "var(--font_family_Bold)",
                        color: "var(--primary_color)",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "32px",
                          fontFamily: "var(--font_family_Bold)",
                          color: "inherit",
                        }}
                      >
                        $
                        {GetCurrencyFixedToTwo(
                          planCost ? planCost : 0,
                          el.currency ? el.currency : "usd",
                          true
                        )}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "10px",
                          color: "var(--text_color_12)",
                        }}
                      >
                        {per_month_text}
                      </Typography>
                      <Typography
                        component={"div"}
                        sx={{
                          fontFamily: "var(--font_family_Regular)",
                          fontSize: "12px",
                          color: "var(--black)",
                          my: "10px",
                        }}
                      >
                        {tax_text}
                      </Typography>
                    </Typography>
                  </Box>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "25px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={mbl_img?.data?.attributes?.alternativeText}
                      src={mbl_img?.data?.attributes?.url || ""}
                      width="15px"
                      className={el?.planType !== "HSD" ? mb_img : ""}
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "14px",
                        color: "var( --text_color)",
                        ml: "15px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          el?.planType === "HSD"
                            ? res1?.buy_data
                            : res1?.streaming.replace(
                                /plandata/g,
                                el?.planData || ""
                              ),
                      }}
                    ></Typography>
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "25px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={hotspot_img?.data?.attributes?.alternativeText}
                      src={hotspot_img?.data?.attributes?.url || ""}
                      width="15px"
                      className={el?.planType !== "HSD" ? mb_img : ""}
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "13px",
                        color: "var( --text_color)",
                        ml: "15px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          el?.planType === "HSD"
                            ? res2?.buy_data
                            : res2?.streaming,
                      }}
                    ></Typography>
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "25px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={mb_data?.data?.attributes?.alternativeText}
                      src={mb_data?.data?.attributes?.url}
                      width="15px"
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "13px",
                        color: "var( --text_color)",
                        ml: "15px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          el?.planType === "HSD"
                            ? res?.buy_data
                            : res?.streaming,
                      }}
                    ></Typography>
                  </Typography>
                </Box>
                <Box
                  className={el.maxLines >= selectedLine ? " " : fade_out}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: "15px",
                    mt: "auto",
                  }}
                >
                  <ButtonCust
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    className={el.maxLines >= selectedLine ? " " : fade_out}
                    onClick={(e: any) => {
                      el.maxLines >= selectedLine
                        ? planSelectionHandler(el, btn_txt)
                        : "";
                    }}
                  >
                    {btn_txt}
                  </ButtonCust>
                </Box>
                <NutritionLabel
                  NLContent={planContent}
                  plandata={el}
                  planCost={planCost}
                  index={index}
                  showAnswer={showAnswer}
                  handleAnswer={handleAnswer}
                  isheading={true}
                />
              </Card>
            );
          })}
        {isMobile && (
          <Typography
            component={"div"}
            style={{
              opacity: 0,
            }}
          >
            div
          </Typography>
        )}
      </Grid>
    </Stack>
  );
};

export default T13_PlanCard;
